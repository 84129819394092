<template>
    <div>
        <buttons></buttons>

        <!------------------------------------------------------------------------------------>
        <!-- main container -->
        <!------------------------------------------------------------------------------------>

        <div class="container">
            <!------------------------------------------------------------------------------------>
            <!-- buttons -->
            <!------------------------------------------------------------------------------------>
            <div class="row align-items-center">
                <div class="col-md-3 offset-md-1" :class="{ 'btn-orange fill': typeId == 0 }">
                    <p class="is-size-5 my-3 pointer" :class="{ 'text-white font-weight-bold': typeId == 0 }"
                        @click="setType(0)">
                        {{ types[0] }}
                    </p>
                </div>
                <div class="col-md-4" :class="{ 'btn-orange fill': typeId == 1 }">
                    <p class="is-size-5 my-3 pointer" :class="{ 'text-white font-weight-bold': typeId == 1 }"
                        @click="setType(1)">
                        {{ types[1] }}
                    </p>
                </div>
                <div class="col-md-3" :class="{ 'btn-orange fill': typeId == 2 }">
                    <p class="is-size-5 my-3 pointer" :class="{ 'text-white font-weight-bold': typeId == 2 }"
                        @click="setType(2)">
                        {{ types[2] }}
                    </p>
                </div>
            </div>

            <!------------------------------------------------------------------------------------>
            <!-- instruction -->
            <!------------------------------------------------------------------------------------>
            <div class="row mt-4">
                <div class="col-md-8 offset-md-3 text-left">
                    <div v-if="consolideer">
                        <p class="font-weight-bold">Gebruiksaanwijzing</p>
                        <p>
                            Op de <b>consolideerkaarten</b> staan spelletjes waarmee je woorden kunt inoefenen. De
                            kaarten zijn bedoeld voor peuters en kleuters. Je kunt zelf inschatten welke spelletjes je
                            met jouw kinderen kunt doen. Wil je meer weten over de consolideerspelletjes en/of hoe je ze
                            in kunt zetten?
                        </p>
                        <a href="/static/handleiding/Handleiding_Consolideerkaarten_WEB_LR.pdf">
                            <button type="button" class="btn btn-outline-dark w-50 mt-0 mb-3">
                                <i class="fa fa-lg fa-file-pdf-o"></i>&nbsp;bekijk / download PDF
                            </button>
                        </a>
                    </div>

                    <div v-if="cooperatieve_werkvormen">
                        <p class="font-weight-bold">Gebruiksaanwijzing</p>
                        <p>
                            Op de kaarten met <b>coöperatieve werkvormen</b> staan spelletjes waarmee je het
                            samenwerkend leren kunt stimuleren. De kaarten zijn bedoeld voor peuters en kleuters. Je
                            kunt zelf inschatten welke spelletjes je met jouw kinderen kunt doen. Wil je meer weten over
                            de coöperatieve spelletjes en/of hoe je ze in kunt zetten?
                        </p>
                        <a href="/static/handleiding/Handleiding_Cooperatieve werkvormen_WEB_LR.pdf">
                            <button type="button" class="btn btn-outline-dark w-50 mt-0 mb-3">
                                <i class="fa fa-lg fa-file-pdf-o"></i>&nbsp;bekijk / download PDF
                            </button>
                        </a>
                    </div>

                    <p v-if="!gymmen">
                        Selecteer de kaarten die je wilt downloaden. Ben je klaar? Klik dan op ‘creëer PDF’. Daarna kun
                        je het bestand downloaden. Na het downloaden kun je het bestand afdrukken. Veel plezier met de
                        consolideerkaarten/ coöperatieve werkvormen!
                    </p>

                    <div v-if="gymmen">
                        <p class="font-weight-bold">Bewegend taal leren voor peuters en kleuters</p>
                        <p>Bewegen en het leren van taal is essentieel voor de ontwikkeling van jonge kinderen. De nieuw
                            ontwikkelde materialen ‘Gymmen met Pim’ combineren beide. Bij het gymmen staan de verschillende
                            bewegingsvormen centraal die horen bij het Athletic Skills Model (ASM). De kinderen voeren een
                            beweegopdracht uit en herhalen ondertussen of aan het einde van de beweegopdracht de geleerde
                            woorden. Zo wordt er gewerkt aan het consolideren (inoefenen) van de eerder aangeboden woorden
                            (stap 4 NT2-didactiek). De lessen kunnen zowel in de gymzaal of speelzaal als buiten gegeven
                            worden. Het is wenselijk de lessen Gymmen met Pim samen met een vakleerkracht bewegingsonderwijs
                            op te pakken. Wil je meer weten over Bewegend leren of Gymmen met Pim? Bekijk dan onderstaande
                            video en/of de handleiding.
                        </p>

                        <p class="font-weight-bold">Wil jij ook Gymmen met Pim?</p>
                        <p>
                            Neem voor het beweeg-deel contact op met:<br>
                            • Jeroen Geerink van Sportaal: j.geerink@sportaal.nl <br>
                            Neem voor het taal-deel contact op met:<br>
                            • Yvette Reuver: pratenmetpim@gmail.com <br>
                            • Liesbeth Kok: bewegendtaalleren@hotmail.com
                        </p>
                        <p class="font-weight-bold">Gebruiksaanwijzing</p>
                        <p> Selecteer een categorie en vervolgens een leskaart. Klik op ‘de leskaart’ of ‘de kaart’ om het
                            te downloaden. Na het downloaden kun je het bestand afdrukken.
                            Veel plezier met Gymmen met Pim!
                        </p>
                    </div>
                </div>
            </div>

            <!------------------------------------------------------------------------------------>
            <!-- row user inputs and previews -->
            <!------------------------------------------------------------------------------------>

            <div v-if="!gymmen" class="row mt-3">
                <!------------------------------------------------------------------------------------>
                <!-- spacer -->
                <!------------------------------------------------------------------------------------>
                <div class="col-md-3 text-left"></div>

                <!------------------------------------------------------------------------------------>
                <!-- preview pane -->
                <!------------------------------------------------------------------------------------>
                <div class="pl-2" v-for="(col, c) in previewCols" :key="col">
                    <div class="pt-2" v-for="(row, r) in previewRows" :key="row">
                        <!-- <div class="col m-0 p-0"> -->
                        <div class="card square" :class="{ 'card-selected': modulus > r * previewCols + c }">
                            <!-- r{{r}} c{{c}} -->
                        </div>
                        <!-- </div> -->
                    </div>
                </div>

                <!------------------------------------------------------------------------------------>
                <!-- selection counter - printing -->
                <!------------------------------------------------------------------------------------>

                <div class="col text-left">
                    <p v-show="nrSelectedImages > 0">
                        {{ nrSelectedImages }}
                        <span v-show="nrSelectedImages == 1">woord geselecteerd</span>
                        <span v-show="nrSelectedImages > 1">woorden geselecteerd</span>
                    </p>
                    <p v-show="nrPages > 0">
                        {{ nrPages }} A4 blad
                        <span v-show="nrPages > 1">en</span>
                    </p>
                    <!-- <button
						type="button"
						class="btn btn-outline-dark w-100"
						@click="createPrint()"
						v-show="showPrint()"
					>
						<i class="fa fa-lg fa-print"></i>&nbsp;print pagina(s)
					</button> -->
                    <button type="button" class="btn btn-outline-dark w-100 mt-1" @click="createPdf()" v-show="showPrint()">
                        <i class="fa fa-lg fa-file-pdf-o"></i>&nbsp;creëer PDF
                    </button>
                </div>

                <div class="col-md-4 text-left"></div>
            </div>

            <div v-if="gymmen" class="row mt-3">
                <div class="col-md-3 text-left">
                    <!------------------------------------------------------------------------------------>
                    <!-- categories -->
                    <!------------------------------------------------------------------------------------>

                    <p class="font-weight-bold">Categorieën:</p>
                    <ul>
                        <li class="pointer" v-for="(cat, index) in categories" :style="cat" @click="setCategory(cat, index)"
                            :key="cat.name">
                            {{ cat.name }}
                        </li>
                    </ul>
                </div>
                <div class="col-md-4">
                    <img src="@/assets/film-gymmen.jpg" class="img-fluid" @click="showModal" />
                </div>
            </div>
            <br />

            <!------------------------------------------------------------------------------------>
            <!-- row with coloured dots -->
            <!------------------------------------------------------------------------------------>

            <div class="row">
                <div class="col-md-9 offset-md-3 dots-bar dots-orange"></div>
            </div>

            <!------------------------------------------------------------------------------------>
            <!-- row with cards -->
            <!------------------------------------------------------------------------------------>

            <div class="row">
                <!-- select all -->
                <div class="col-md-3 text-left">
                    <!-- consolideerkaarten cooperatieve werkvormen -->
                    <p v-if="!gymmen" class="pointer" @click="selectAllCards()">alles selecteren</p>

                    <!-- gymmen -->
                    <div v-else>
                        <p class="font-weight-bold">Leskaarten:</p>
                        <ul>
                            <li class="pointer" v-for="(theme, index) in leskaarten_K" :key="index"
                                :class="{ underline: theme.active, disabled: theme.present == '' }"
                                @click="setTheme(index + 1, theme)">
                                {{ index + 1 }}. {{ theme }}
                            </li>
                        </ul>
                    </div>

                </div>

                <!-- cards -->
                <div class="col-md-9">
                    <!-- consolideerkaarten cooperatieve werkvormen -->
                    <div v-if="!gymmen" class="row">
                        <div class="col-sm-4 col-6 p-2" v-for="image in images" :key="image.id">
                            <div class="card h-100" :class="{ 'card-selected': image.selected }" @click="selectCard(image)">
                                <div class="card-img-top">
                                    <img class="img-fluid" :src="image.imageName" />
                                </div>
                                <div class="card-body">
                                    <img class="img-fluid" :src="image.dots" alt="stippels" />
                                    <div>
                                        <p v-if="cooperatieve_werkvormen" class="medium" :style="{ color: catColor }"
                                            v-html="image.plaatje"></p>
                                        <div v-for="z in image.zin" :key="z.id">
                                            <p class="medium" v-html="z"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- gymmen -->
                    <div v-else class="row">
                        <div class="col-sm-4 col-6 p-3">
                            <img class="img-fluid shadow mb-3" :src="leskaartPng" />
                            <a v-if="leskaartPdf != ''" :href="leskaartPdf">download leskaart</a>
                        </div>
                        <div class="col-sm-4 col-6 p-3">
                            <img class="img-fluid shadow mb-3" :src="gymkaartPng" />
                            <a v-if="gymkaartPdf != ''" :href="gymkaartPdf">download gymkaart</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modals-container />
    </div>
</template>

<script>
import top from './Top.vue';
import buttons from './Buttons.vue';
import ax from '../js/axios.js';
import _ from 'underscore';

import modalComponent from '@/components/Modal.vue';

export default {
    data: () => ({
        consolideer: false,
        cooperatieve_werkvormen: false,
        gymmen: false,

        table: ['consolideer', 'cooperatief'],
        types: ['consolideerkaarten', 'coöperatieve werkvormen', 'gymmen met Pim'],
        typeId: 0,
        typeNr: 1, // vertelkaarten consolideerkaarten cooperatieve_werkvormen gymmen_met_pim
        imagePath: '',

        categories: [
            {
                name: 'peuters',
                abbr: 'P',
                active: false,
                color: '#c42737',
            },
            {
                name: 'kleuters',
                abbr: 'K',
                active: false,
                color: '#459dd7',
            },
            // {
            //     name: 'uitbreiding',
            //     abbr: 'U',
            //     active: false,
            //     color: '#03965b',
            // },
        ],

        activeCategory: '',
        path: '',
        leskaarten_K:
            [
                'Balanceren en Vallen',
                'Bewegen op en maken van Muziek',
                'Gaan en Lopen',
                'Gooien Vangen Mikken en Slaan',
                'Klimmen en Klauteren',
                'Rollen, Duikelen en Draaien',
                'Springen en Landen',
                'Stoeien en Vechten',
                'Trappen schieten mikken',
                'Zwaaien en Slingeren'
            ],
        leskaartPng: '',
        leskaartPdf: '',
        gymkaartPng: '',
        gymkaartPdf: '',

        catColor: '#ed682b',

        previewCols: 0,
        previewRows: 0,
        nrImagesPerPage: 0,

        images: [],
        selectedImages: [],
        nrSelectedImages: 0,
        modulus: 0,
        nrPages: 0,
        nrWords: 0,

        marginAfterRow: 0,

        dots: '',
        printSettings: {},
    }),

    components: {
        top,
        buttons,
        modalComponent
    },

    created() {
        console.clear();
        console.log('typeId', localStorage.typeId);
        this.setType(localStorage.typeId);
        this.dots = require('@/assets/dotsOrange.png');
    },

    methods: {
        showModal() {
            this.$modal.show(
                modalComponent,
                {
                    clickToClose: false
                },
                {
                    width: '60%',
                    height: 'auto'
                },
            );
        },

        // createPrint: function () {
        // 	this.$router.push({
        // 		name: 'Print',
        // 		params: {
        // 			printSettings: this.printSettings,
        // 		},
        // 	});
        // },

        setTheme(index, theme) {
            this.leskaartPng = '/static/gymmen/' + this.activeCategory + '_Leskaarten_PNG/' + index + '_Leskaart_' + theme + '_' + this.activeCategory + '.png';
            this.leskaartPdf = '/static/gymmen/' + this.activeCategory + '_Leskaarten_PDF/' + index + '_Leskaart_' + theme + '_' + this.activeCategory + '.pdf';
            this.gymkaartPng = '/static/gymmen/' + this.activeCategory + '_Gymkaarten_PNG/' + index + '_Gymkaart_' + theme + '_' + this.activeCategory + '.png';
            this.gymkaartPdf = '/static/gymmen/' + this.activeCategory + '_Gymkaarten_PDF/' + index + '_Gymkaart_' + theme + '_' + this.activeCategory + '.pdf';
        },

        setCategory: function (cat, index) {
            console.log(index)
            // only selected theme becomes active
            this.categories.forEach((c) => {
                c.active = false;
            });
            this.categories[index].active = true;

            // set category
            this.category = cat.abbr;
            this.dots = require('@/assets' + '/dots' + cat.abbr + '.png');
            this.catColor = cat.color;

            let name = this.categories[index].name;
            this.activeCategory = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();

            this.leskaartPng = '';
            this.leskaartPdf = '';
            this.gymkaartPng = '';
            this.gymkaartPdf = '';
        },

        createPdf: function () {
            this.$router.push({
                name: 'Pdf',
                params: {
                    printSettings: this.printSettings,
                },
            });
        },

        showGenerate: function () {
            var nr = this.nrWords;

            // if less images present than use that for maximum
            if (this.images.length < nr) nr = this.images.length;

            // show when nrpages > 0
            return this.nrPages > 0;
        },

        showPrint: function () {
            // show when nrpages > 0
            return this.nrPages > 0;
        },

        selectCard: function (img) {
            // console.log(img);
            img.selected = !img.selected;

            if (img.selected) {
                this.selectedImages.push(img);
                this.nrSelectedImages++;
                this.selectedImages.push(img);
                this.nrSelectedImages++;
            } else {
                this.selectedImages.pop(img);
                this.nrSelectedImages--;
                this.selectedImages.pop(img);
                this.nrSelectedImages--;
            }

            this.nrPages = Math.ceil(this.nrSelectedImages / this.nrImagesPerPage);
            this.modulus = this.nrSelectedImages % this.nrImagesPerPage;

            this.printSettings = {
                images: this.selectedImages,
                dots: this.dots,
                catColor: this.catColor,
                nrPages: this.nrPages,
                nrImagesPerPage: this.nrImagesPerPage,
                nrCols: this.previewCols,
                nrRows: this.previewRows,
                marginAfterRow: this.marginAfterRow,
                typeNr: this.typeNr,
                consolideer: this.consolideer,
                cooperatieve_werkvormen: this.cooperatieve_werkvormen,
            };
        },

        selectAllCards: function () {
            this.images.forEach((image) => {
                this.selectCard(image);
            });
        },

        imageLink: function (u) {
            let link = this.imagePath + u.plaatje + '.png';

            return link;
        },

        handleSentences: function (sent, target) {
            let style1, style2;

            // style definitions for print
            if (target == 'screen') {
                style1 = '<span style="color:' + this.catColor + '">';
                style2 = '</span>';
            }

            // style definitions for pdf
            if (target == 'pdf') {
                style1 = '<';
                style2 = '>';
            }

            // get sentences, trim, do not add dot to end
            let sentences = sent.split('\n');
            sentences = _.without(sentences, '', ' ');
            sentences = _.map(sentences, function (s) {
                let sn = s.trim();

                sn = sn.replace(/\[/g, style1);
                sn = sn.replace(/]/g, style2);

                return sn;
            });

            if (target == 'screen') {
                // fill with empty sentences upto 3
                for (var i = sentences.length; i < 3; i++) {
                    sentences.push('&nbsp;');
                }
            }

            return sentences;
        },

        clone: function (u) {
            return JSON.parse(JSON.stringify(u));
        },

        getImages: function (tableNr) {
            // console.log('getImages');
            let url = this.table[tableNr];

            this.images = [];
            ax.getData(url)
                .then((response) => {
                    if (response.length > 0) {
                        response.forEach((u) => {
                            var imageName = this.imageLink(u);
                            var newImage = this.clone(u);

                            // handle special characters
                            if (imageName.indexOf('è') != -1) {
                                imageName = imageName.replace('è', 'e');
                            }

                            newImage.imageName = imageName;
                            newImage.selected = false;
                            newImage.dots = require('@/assets/dotsOrange.png');
                            newImage.zin = this.handleSentences(u.zin, 'screen');
                            newImage.zinPdf = this.handleSentences(u.zin, 'pdf');
                            this.images.push(newImage);
                        });
                    }
                    // console.log(this.images);
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        setType: function (tableNr) {
            this.images = [];
            this.selectedImages = [];
            this.typeId = tableNr;
            localStorage.typeId = this.typeId;

            if (tableNr == 0) {
                this.imagePath = '/static/consolideerkaarten/';
                this.consolideer = true;
                this.cooperatieve_werkvormen = false;
                this.gymmen = false;
            }
            if (tableNr == 1) {
                this.imagePath = '/static/cooperatievewerkvormen/';
                this.consolideer = false;
                this.cooperatieve_werkvormen = true;
                this.gymmen = false;
            }
            if (tableNr == 2) {
                this.imagePath = '/static/gymmen/';
                this.consolideer = false;
                this.cooperatieve_werkvormen = false;
                this.gymmen = true;
            }

            if (tableNr != 2) {

                // woordkaarten vertelkaarten
                this.previewCols = 2;
                this.previewRows = 3;

                this.nrImagesPerPage = this.previewCols * this.previewRows;
                this.nrPages = Math.ceil(this.nrSelectedImages / this.nrImagesPerPage);
                this.modulus = this.nrSelectedImages % this.nrImagesPerPage;
                this.marginAfterRow = 0;

                this.getImages(tableNr);
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
    color: black;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.jumbotron-fluid {
    /*padding: 40px 0;*/
    background-color: #dddddd;
}

.underline {
    text-decoration: underline;
}

.disabled {
    display: none;
}

.card-image {
    padding-top: 20px;
}

.card-content {
    padding-top: 0;
}

div.col a {
    color: #4a4a4a;
}

div.col-3 a:hover {
    text-decoration: underline;
}

div.col-3 ul li:hover {
    text-decoration: underline;
}

.dots-bar {
    /* border: solid 1px blue; */
    height: 20px;
    background-repeat: repeat-x;
    background-size: 20px;
}

.dots-orange {
    background-image: url('../assets/stippel_orange.svg');
}

.color-orange {
    color: '#ed682b';
}

.card-img-top {
    padding: 20px 20px 0 20px;
}

.card-body {
    padding: 0 20px 0 20px;
}

.card-selected {
    background-color: #e9e9eb;
}

.medium {
    font-weight: 400;
}

.square {
    border: solid 1px red;
    width: 60px;
    height: 60px;
}

.pointer {
    cursor: pointer;
}
</style>
